import React, { useState } from 'react'
import Layout from '../components/Layout'
import styled from "styled-components"
import { device } from '../components/Device'
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';
import emailJSConfig from '../utils/emailJSConfig'
import { init } from 'emailjs-com';
import Loading from '../components/Loading';
import WhatsappImage from '../images/wpp.png'
import MailImage from '../images/mail-white.png'
import SEO from '../components/SEO'

init("user_88YUa6DCEGdnoHYXNYIXB");

const Container = styled.div`
    margin: auto;
    width: 1024px;
    padding-top: 80px;
    padding-bottom: 150px;
    @media ${device.laptop} {
        width: 768px;
    }

    @media ${device.tablet} {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const FlexContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
`

const ContactFormContainer = styled.div`
  width: 50%;
  max-width: 480px;
  @media ${device.tablet} {
    width: 100%;
  }
`

const Title = styled.h1`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.01em;

  /* Core / 100 */

  color: #3E3E3E;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
`

const Subtitle = styled.div`
  /* P1 - Regular Text Body */

  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */


  /* Core / 100 */

  color: #3E3E3E;
  margin-top: 32px;
`

const MapLocationContainer = styled.div`
  width: 50%;
  @media ${device.tablet} {
    width: 100%;
  }
`

const ContactInfoContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  background: rgba(252, 238, 225, 0.5);
  padding: 48px 0;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
`
const MapFrame = styled.iframe`
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 600px;
  margin-top: 90px;
  z-index: 0;
`

const MapButton = styled.a`
  /* Core / 0 */

  background: #FFFFFF;
  /* Secondary / Dark */

  border: 1px solid #C8625B;

  /* P1 - Regular Text Body */

  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */


  /* Secondary / Dark */

  color: #C8625B;
  border-radius: 4px;
  padding: 17px 88px;
  position: relative;
  z-index: 1;
  margin-top: -90px;
  display: block;
  width: 270px;
  box-sizing: border-box;
  margin-left: 24%;
  @media ${device.laptop} {
    margin-left: 14%;
  }

  @media ${device.tablet} {
    margin-left: 17%;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0;
    padding: 17px 112px;
  }
`


const Input = styled.input`
  background: #F2F2F2;;
  border-radius: 8px;
  height: 53px;
  display: block;
  width: 90%;

  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
/* identical to box height, or 150% */
  padding-left: 5%;
  padding-right: 5%;

/* Core / 60 */
  border: none;
  color: #3E3E3E;

  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #A6A6A5;
  }
`
const FormGroup = styled.div`
  margin-bottom: 24px;
`

const Label = styled.label`
font-family: Google Sans;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Core / 100 */

color: #3E3E3E;
padding-bottom: 12px;
display: block;
`


const FormContainer = styled.div`
    width: 100%;
    padding-top: 40px;
    padding-bottom: 90px;
`

const Error = styled.span`
  color: red;
  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-left: 10%;
`

const TextArea = styled.textarea`
  width: 90%;
  background: #F2F2F2;;
  border-radius: 8px;

  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
/* identical to box height, or 150% */
  padding-left: 5%;
  padding-right: 5%;

/* Core / 60 */
  border: none;
  color: #3E3E3E;
  padding-top: 5%;
  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #A6A6A5;
  }
  resize: none;
`

const Button = styled.input`
  height: 55px;
  background: #3e3e3e;
  color: white;
  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  border: none;
  border-radius: 4px;
  margin-top: 24px;
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 300px;

`


const BoldSpan = styled.span`
  font-family: Google Sans;
  font-weight: 500;
  line-height: 5px;
`

const InfoTitle = styled.h3`
  font-family: Google Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Core / 100 */

  color: #3E3E3E;
  margin-bottom: 15px;
  margin-top: 15px;
`


const MailLink = styled.a`
font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
/* identical to box height, or 150% */

text-align: center;
text-decoration-line: underline;

color: #2D6AC7;

`
const WhatsAppBox = styled.div`
  display: flex;
  align-items: center;  
`

const WhatsApp = styled.img`
  float: left;
  width: 32px;
`


export default function CertificateForm(props) {
  const { pageContext } = props
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    const templateParams = {
      name: data.name || "Não informado",
      mail: data.mail || "Não informado",
      message: data.message || "Não informado"
    };

    setLoading(true)
    emailjs.send(emailJSConfig.serviceId, emailJSConfig.templateId.contact, templateParams)
      .then(() => {
        setLoading(false)
        alert('Mensagem enviada com sucesso!')
        reset()
      }, () => {
        alert('Houve um erro ao enviar sua mensagem. Tente novamente.')
        setLoading(false)
      });
  }
  return (
    <Layout>
      <SEO title={pageContext.title} />
      
      <Container>
        <FlexContainer>
          <ContactFormContainer>
            <Title>{pageContext.title}</Title>
            <Subtitle dangerouslySetInnerHTML={{ __html: pageContext.content }} />
            <FormContainer>
              <form onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                  <Label>Nome completo *</Label>
                  <Input name="name" placeholder="Digite seu nome" ref={register({ required: true })} type="text" />
                  {errors.name && <Error>Este campo é obrigatório</Error>}
                </FormGroup>

                <FormGroup>
                  <Label>E-mail</Label>
                  <Input name="mail" placeholder="Digite seu e-mail" type="mail" ref={register({ required: true })} />
                  {errors.mail && <Error>Este campo é obrigatório</Error>}
                </FormGroup>

                <FormGroup>
                  <Label>Mensagem</Label>
                  <TextArea rows="7" name="message" placeholder="Digite seu e-mail" ref={register({ required: true })} />
                  {errors.message && <Error>Este campo é obrigatório</Error>}
                </FormGroup>

                {isLoading ? <Loading /> : <Button type="submit" />}

              </form>
            </FormContainer>
          </ContactFormContainer>

          <MapLocationContainer>
            <MapFrame
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3838.7417783692013!2d-48.102012634277344!3d-15.817564010620117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935bcce98640e8ad%3A0x82724e6d22e33e85!2s3%20-%20St.%20M%20CNM%201%20-%20Ceil%C3%A2ndia%2C%20Bras%C3%ADlia%20-%20DF%2C%2072215-110!5e0!3m2!1spt-BR!2sbr!4v1614230459696!5m2!1spt-BR!2sbr"
              frameborder="0"
              allowfullscreen
              aria-hidden="false"
              tabindex="0" />
            <MapButton target="_blank" href="https://www.google.com/maps/dir//-15.8178198,-48.1022207/@-15.817696,-48.102099,19z?hl=pt-BR">Como chegar</MapButton>
          </MapLocationContainer>
        </FlexContainer>


        <ContactInfoContainer>
          <InfoBox>
            <img src={MailImage} />
            <InfoTitle>Endereço</InfoTitle>
            <BoldSpan>Centro Empresarial Ceilândia</BoldSpan><br />
            <span style={{"textAlign": "center"}}>CNM 1 Bloco I Lote 03, 4° Andar, sala 401/402</span><br />
            <span>Ceilândia - Brasília/DF</span><br />
            <span>CEP 72215-509</span>
          </InfoBox>
          
          <InfoBox>
            <img src={MailImage} />
            <InfoTitle>Telefones</InfoTitle>
            <BoldSpan>(61) 3581-2795</BoldSpan><br />
            <BoldSpan>(61) 3581-6607</BoldSpan><br /><br />
            <MailLink target="_blank" href="https://wa.me/556185094174">
              <WhatsAppBox>
                <WhatsApp src={WhatsappImage} />
                <span>WhatsApp: +55 61 8509-4174</span>
              </WhatsAppBox>
                  <WhatsAppBox>
                <WhatsApp src={WhatsappImage} />
                <span>WhatsApp: +55 61 8182-5119</span>
              </WhatsAppBox>
                  <WhatsAppBox>
                <WhatsApp src={WhatsappImage} />
                <span>WhatsApp: +55 61 98515-0952</span>
              </WhatsAppBox>
            </MailLink>
          </InfoBox>
          
          <InfoBox>
            <img src={MailImage} />
            <InfoTitle>E-mail</InfoTitle>
            <MailLink href="mailto:7rcrtd@gmail.com?subject=Contato%20Site">7rcrtd@gmail.com</MailLink>
          </InfoBox>
        </ContactInfoContainer>
      </Container>
    </Layout>
  )
}
